import { AxiosPromise } from 'axios';
import { KIDOverviewData } from '../../types/pages/raidr.types';
import { AxiosInstance } from '../../utilities/requestClient';

export const getKIIDSGeneratorOverviewData = (): AxiosPromise<
  KIDOverviewData[]
> => AxiosInstance.get('kiids_generator_get_overview_data');

export function getUcitsKiidsOverviewViewUrl() {
  return 'ucits-kiids-overview-view';
}

export function getUcitsKiidsOverviewEditUrl() {
  return 'ucits-kiids-overview-edit';
}

export function getPriipsKidsOverviewUrl() {
  return 'priips-kids-overview';
}

export function getReviewDataSpecificVersions(
  fundId: string,
  isin: string,
  target: string,
  comparison: string,
  documentLanguage: string,
) {
  return `kiid_generator_get_review_changes/${fundId}/${isin}/${target}/${comparison}/${documentLanguage}`;
}

export function reviewAllDataChangesURL() {
  return 'kiid_generator_get_review_changes/';
}

export function getKidFiguresDataByShareClassUrl(
  fundId: string,
  shareClass: string,
): string {
  return `get_kid_figures_data_by_share_class/${fundId}/${shareClass}`;
}

export function getRaidrEsgDelegatedActsUrl(fundId: string): string {
  return `/get_raidr_data/esg_delegated_acts/English/${fundId}`;
}

export function getRaidrOverviewDataUrl(section: string): string {
  return `get_raidr_overview_data/${section}`;
}

export function generateFactSheetsEditorUrl(
  fundId: string,
  fundCode: string,
  fundName: string,
): string {
  return `fact-sheets-editor?fundId=${fundId}&fundCode=${fundCode}&fundName=${fundName}`;
}

export function generateDelegatedActsEditorUrl(
  fundId: string,
  fundCode: string,
  fundName: string,
): string {
  return `delegated-acts-editor?fundId=${fundId}&fundCode=${fundCode}&fundName=${fundName}`;
}
