import { FC, FunctionComponent, useEffect } from 'react';
import GeneralFundInfoPage from './GeneralFundInfoPage';
import GeneralOverviewPage from './GeneralOverviewPage';
import useRouteParams from '../../hooks/useRouteParams';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFund,
  updatePage,
  updateSection,
} from '../../redux/pages/actions';
import {
  activeDateSelector,
  activePageSelector,
  createActiveFundSelectorBySection,
  createPageDetailsSelector,
  createSectionByIdSelector,
} from '../../redux/pages/selectors';
import { flexify } from '../../utilities/cssMixins';
import { mainColors } from '../../styling/theme';
import { useAppendOverviewFunds } from '../../hooks/useAppendOverviewFunds';
import GeneralComponentErrorShield from './GeneralComponentErrorShield';
import { clearPdfExports } from '../../redux/pdfExport/actions';
import { Grid, Typography } from '@mui/material';

const GeneralPage: FC<RouteComponentProps> = (props) => {
  // these params are defined in the route in ./routes/Pages.tsx
  const { section, pageId } = useParams<any>();
  const pageDetails = useSelector(createPageDetailsSelector(pageId, section));
  const { fundId } = useRouteParams(['fundId']);
  const history = useHistory();
  const activeFundSelector = createActiveFundSelectorBySection(section);
  const location = useLocation();
  const sectionDetails = useSelector(createSectionByIdSelector(section));
  const activePage = useSelector(activePageSelector);
  const activeDate = useSelector(activeDateSelector);

  const activeFund = useSelector(activeFundSelector);
  const dispatch = useDispatch();

  const fundsData = useAppendOverviewFunds(
    sectionDetails?.fundTypes,
    sectionDetails?.rsResources,
  ) ?? {
    isFetching: true,
    error: null,
    data: [],
  };

  const pageRequestedSelector = createPageDetailsSelector(pageId, section);
  const pageRequested = useSelector(pageRequestedSelector);
  useEffect(() => {
    dispatch(clearPdfExports());
  }, [fundId]);

  useEffect(() => {
    dispatch(updatePage(pageId, pageDetails?.pageArea || '', section));
    dispatch(clearPdfExports());
  }, [pageId]);

  useEffect(() => {
    dispatch(updateSection(section));
    dispatch(clearPdfExports());
  }, [section]);

  useEffect(() => {
    if (
      pageRequested &&
      activeFund &&
      pageRequested.allowFundPages &&
      location.search.indexOf('fundId') === -1
    ) {
      history.push(
        `${location.pathname}${location.search.indexOf('?') === -1 ? '?' : ''}${
          location.search
        }&fundId=${activeFund.id}`,
      );
    }
  }, [activeFund, pageRequested]);

  useEffect(() => {
    if (!fundId) {
      dispatch(updateFund(section, '', activeDate));
    } else {
      dispatch(updateFund(section, fundId, activeDate));
    }
  }, [section, pageId, fundId]);

  if (pageRequested?.locked) return <PageLocked />;

  if (fundId && section !== 'raidr')
    return (
      <GeneralFundInfoPage
        {...props}
        section={section}
        pageId={pageId}
        fundId={fundId}
      />
    );

  if (pageId === activePage)
    return (
      <GeneralComponentErrorShield
        dataObjects={[fundsData]}
        customLoadingMessages={[
          `Loading ${sectionDetails?.fundTypes} overview data...`,
        ]}
        customErrorMessage={'Failed to load overview data'}
      >
        {section != 'raidr' ? (
          <Grid container spacing={2} style={{ padding: 8 }}>
            <GeneralOverviewPage
              {...props}
              section={section}
              pageName={pageId}
            />
          </Grid>
        ) : (
          <GeneralOverviewPage {...props} section={section} pageName={pageId} />
        )}
      </GeneralComponentErrorShield>
    );

  return null;
};

export default GeneralPage;

const PageLocked: FunctionComponent = () => {
  return (
    <div
      style={{
        ...flexify('center', 'center', 'column'),
        height: 'calc(100vh - 16rem)',
        width: '100%',
      }}
    >
      <Typography variant="h2" style={{ color: mainColors.mainBlue }}>
        Page Currently Unavailable
      </Typography>
    </div>
  );
};
