import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateNewKID } from './api.routes';

export function useGenerateNewKIDPdf() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FormData) => generateNewKID(data),
    onMutate: () => {
      console.log('mutate');
    },

    onError: () => {
      console.log('error');
    },

    onSuccess: () => {
      console.log('success');
    },

    onSettled: async (_, error, variables) => {
      console.log('settled');
    },
  });
}
