import { useQuery } from '@tanstack/react-query';
import { getKIDContentData, getKIDSGeneratorOverviewData } from './api.routes';

export function useKIDSOverviewData() {
  return useQuery({
    queryKey: ['kids-overview-data'],
    queryFn: getKIDSGeneratorOverviewData,
  });
}

export function useKIDSContentData(
  fundId: string | null,
  isin: string | null,
  documentLanguage: string | null,
  version?: string | null,
) {
  return useQuery({
    queryKey: ['kids-content-data', fundId, isin, documentLanguage, version],
    queryFn: () =>
      getKIDContentData(fundId!, isin!, documentLanguage!, version), // Enabled ensures all parameters are present
    enabled: !!fundId && !!isin && !!documentLanguage,
  });
}
